import { Labels } from '../../shared-components/global/labels.js';

Labels.set('ui-daterange', 'TODAY', 'Täna');
Labels.set('ui-daterange', 'YESTERDAY', 'Eile');
Labels.set('ui-daterange', 'CURRENT_WEEK', 'Jooksev nädal');
Labels.set('ui-daterange', 'CURRENT_MONTH', 'Jooksev kuu');
Labels.set('ui-daterange', 'CURRENT_YEAR', 'Aasta algusest');
Labels.set('ui-daterange', 'PREV_YEAR', 'Eelmine aasta');
Labels.set('ui-daterange', 'PREV_WEEK', 'Eelmine nädal');
Labels.set('ui-daterange', 'PREV_MONTH', 'Eelmine kuu');
Labels.set('ui-daterange', 'PREV_MONTH_TODAY', 'Eelmise kuu algusest tänaseni');
Labels.set('ui-daterange', 'PERIOD_START', 'Perioodi algus');
Labels.set('ui-daterange', 'PERIOD_END', 'Perioodi lōpp');
Labels.set('ui-daterange', 'TIME_START', 'Algaeg');
Labels.set('ui-daterange', 'TIME_END', 'Lōppaeg');
