import { Labels } from '../../shared-components/global/labels.js';

Labels.set('ui-signing', 'TITLE', 'Digitaalallkirjastamine');
Labels.set(
    'ui-signing',
    'DIGIPASS_CHALLENGE',
    'Vali PIN-kalkulaatorist „APPLI 2“ ja sisesta sinna see kood:'
);
Labels.set('ui-signing', 'DIGIPASS_PASSWORD', 'PIN-kalkulaatori loodud kood:');
Labels.set('ui-signing', 'DIGIPASS_PLACEHOLDER', 'APPLI 2');
Labels.set('ui-signing', 'DIGIPASS_CONFIRM', 'Kinnitan');
Labels.set(
    'ui-signing',
    'DIGIPASS_ERROR_MESSAGE',
    'PIN-kalkulaatoriga kinnitamine ebaõnnestus.'
);

Labels.set(
    'ui-signing',
    'SMART_ID_RUNNING',
    'Teavitus saadeti Smart-ID nutiseadmesse. Kontrollige koodi!'
);
Labels.set('ui-signing', 'SMART_ID_CHALLENGE', 'Sinu kontrollkood:');
Labels.set(
    'ui-signing',
    'SMART_ID_INTRO_MESSAGE',
    'Veendu, et oled kinnitatava infoga nõus. Kahtluse korral mine tagasi eelmisele lehele ja kontrolli üle.'
);
Labels.set('ui-signing', 'SMART_ID_EXPLANATION', '');
Labels.set(
    'ui-signing',
    'SMART_ID_SENDING_MESSAGE',
    'Päring on saadetud Sinu seadmesse. Võrdle koode ja sisesta PIN2.'
);

Labels.set(
    'ui-signing',
    'MOBILE_ID_RUNNING',
    'Sõnum on saadetud Teie telefonile.'
);
Labels.set('ui-signing', 'MOBILE_ID_CHALLENGE', 'Sinu kontrollkood:');
Labels.set(
    'ui-signing',
    'MOBILE_ID_INTRO_MESSAGE',
    'Veendu, et oled kinnitatava infoga nõus. Kahtluse korral mine tagasi eelmisele lehele ja kontrolli üle.'
);
Labels.set('ui-signing', 'MOBILE_ID_EXPLANATION', '');
Labels.set(
    'ui-signing',
    'MOBILE_ID_SENDING_MESSAGE',
    'Päring on saadetud Sinu seadmesse. Võrdle koode ja sisesta PIN2.'
);

Labels.set(
    'ui-signing',
    'EPARAKSTS_MOBILE_RUNNING',
    'Teavitus saadeti eParaksts mobile nutiseadmesse. Kontrollige koodi!'
);
Labels.set('ui-signing', 'EPARAKSTS_MOBILE_CHALLENGE', 'Sinu kontrollkood:');
Labels.set(
    'ui-signing',
    'EPARAKSTS_MOBILE_INTRO_MESSAGE',
    'Veendu, et oled kinnitatava infoga nõus. Kahtluse korral mine tagasi eelmisele lehele ja kontrolli üle.'
);
Labels.set('ui-signing', 'EPARAKSTS_MOBILE_EXPLANATION', '');
Labels.set(
    'ui-signing',
    'EPARAKSTS_MOBILE_SENDING_MESSAGE',
    'Päring on saadetud Sinu seadmesse. Võrdle koode ja sisesta PIN2.'
);
Labels.set(
    'ui-signing',
    'EPARAKSTS_MOBILE_ERROR_MESSAGE',
    'eParaksts mobilega allkirjastamine ebaõnnestus.'
);

Labels.set(
    'ui-signing',
    'SIMPLE_ID_RUNNING',
    'Kinnita biomeetria või PIN-koodiga. Veendu, et kontrollkoodid kattuvad.'
);
Labels.set('ui-signing', 'SIMPLE_ID_CHALLENGE', 'Sinu kontrollkood:');
Labels.set(
    'ui-signing',
    'SIMPLE_ID_INTRO_MESSAGE',
    'Veendu, et oled kinnitatava infoga nõus. Kahtluse korral mine tagasi eelmisele lehele ja kontrolli üle.'
);
Labels.set('ui-signing', 'SIMPLE_ID_EXPLANATION', '');
Labels.set(
    'ui-signing',
    'SIMPLE_ID_SENDING_MESSAGE',
    'Päring on saadetud Sinu seadmesse. Võrdle kontrollkoode ning kinnita biomeetria või PIN-koodiga.'
);

Labels.set('ui-signing', 'CANCEL_BUTTON_LABEL', 'Tagasi');
Labels.set('ui-signing', 'CONFIRM_BUTTON_LABEL', 'Kinnitan');

Labels.set(
    'ui-signing',
    'SMART_ID_ERROR_MESSAGE',
    'Smart-IDga allkirjastamine ebaõnnestus.'
);
Labels.set(
    'ui-signing',
    'MOBILE_ID_ERROR_MESSAGE',
    'Mobiil-IDga allkirjastamine ebaõnnestus.'
);
Labels.set(
    'ui-signing',
    'EPARAKSTS_MOBILE_ERROR_MESSAGE',
    'Allkirjastan eParaksts mobile PIN2-ga.'
);
Labels.set(
    'ui-signing',
    'SIMPLE_ID_ERROR_MESSAGE',
    'Biomeetria või PIN-koodiga kinnitamine ebaõnnestus.'
);
Labels.set(
    'ui-signing',
    'CERTIFICATE_ERROR_MESSAGE',
    'ID-kaardiga allkirjastamine ebaõnnestus.'
);

Labels.set(
    'ui-signing',
    'SMART_ID_BUTTON_LABEL',
    'Allkirjastan Smart-ID PIN2-ga'
);
Labels.set(
    'ui-signing',
    'MOBILE_ID_BUTTON_LABEL',
    'Allkirjastan Mobiil-ID PIN2-ga'
);
Labels.set(
    'ui-signing',
    'SIMPLE_ID_BUTTON_LABEL',
    'Kinnitan biomeetria või PIN-koodiga'
);
Labels.set(
    'ui-signing',
    'CERTIFICATE_BUTTON_LABEL',
    'Allkirjastan ID-kaardi PIN2-ga'
);
Labels.set('ui-signing', 'DIGIPASS_BUTTON_LABEL', 'Kinnitan');

Labels.set(
    'ui-signing',
    'HWCRYPTO_DEFAULT_ERROR',
    'ID-kaardi plugin ei tööta. Taaskäivita veebibrauser ja proovi uuesti. {{info-link}}'
);
Labels.set(
    'ui-signing',
    'HWCRYPTO_NO_CERTIFICATES',
    'Kehtivat ID-kaardi sertifikaati ei leitud. Võta ID-kaart lugejast välja. Pane kaart tagasi lugejasse ja proovi uuesti. {{info-link}}'
);
Labels.set(
    'ui-signing',
    'HWCRYPTO_USER_CANCEL',
    'Allkirjastamine tühistati, sest vajutasid nupule „Tühista“.'
);
Labels.set(
    'ui-signing',
    'HWCRYPTO_DRIVER_ERROR',
    'Esines viga ID-kaardi tarkvaras. Kontrolli, kas tarkvara on uuendatud. Vajadusel uuenda tarkvara, taaskäivita veebibrauser ja proovi uuesti. {{info-link}}'
);
Labels.set(
    'ui-signing',
    'HWCRYPTO_NO_IMPLEMENTATION',
    'ID-kaardi plugina töös tekkis tõrge. Vajaduse korral uuenda ID-kaardi tarkvara ning kontrolli, et Web eID laiendus on veebilehitsejas aktiveeritud. Taaskäivita brauser ja proovi uuesti.'
);

Labels.set('ui-signing', 'LINK_PLACEHOLDER', 'Loe lähemalt ID-kaardi kohta');
